import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring';
import { Helmet } from 'react-helmet';

import { sendMail } from './service';

import illustration from '../images/contact_illustration.png';

import './contact.scss';

function Contact(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [successScreen, setSuccessScreen] = useState(false);
    const [validation, setValidation] = useState({
        name: false,
        email: false,
        message: false
    });

    const [formData, setFormData] = useState({
        title: '',
        name: '',
        email: '',
        message: ''
    });

    const mainTransition = useTransition(successScreen, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    const handleValidate = () => {
        const validate = { ...validation };

        if (!formData.email || !/^\S+@\S+\.\S+$/.test(formData.email)) validate.email = true;
        else validate.email = false;

        validate.name = !formData.name;
        validate.message = !formData.message;

        const validated = !validate.name && !validate.email && !validate.message

        setValidation({ ...validate });

        return validated;
    }

    const handleChange = e => {
        if (e.target.value) setValidation({ ...validation, [e.target.name]: false })

        if (validation.email && e.target.name === "email") {
            if (!/^\S+@\S+\.\S+$/.test(e.target.value)) setValidation({ ...validation, email: true })
            else setValidation({ ...validation, email: false });
        }

        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setLoading(true);
            sendMail(formData).then((res, err) => {
                if (!err) {
                    setSuccessScreen(true);
                    setLoading(false);
                }
            }).catch(err => {
                setError(true);
                setLoading(false);
                console.error(err);
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Maca - Contact us</title>
            </Helmet>
            <div className='ContactModal'>
                <animated.div className={`ContactModal__inner ${successScreen ? "success" : ""}`} style={props.style}>
                    <div className="ContactModal__inner__close" onClick={props.onClose}></div>
                    <div className={`ContactModal__inner__content ${successScreen && 'success'}`}>
                        <div className="ContactModal__inner__content-left">
                            <h1 className={`${successScreen ? 'message-sent' : ''}`}>{successScreen ? 'MESSAGE SENT' : 'Contact us'}</h1>

                            <img src={illustration} className={`${successScreen ? 'message-sent' : ''}`} alt="contact illustration" />
                        </div>
                        {mainTransition((style, screen) => !screen && (
                            <animated.div className={`ContactModal__inner__content-right`} style={style}>
                                <form onSubmit={handleSubmit}>
                                    <input onChange={handleChange} type="text" name="title" value={formData.title} placeholder="Title" />
                                    <input onChange={handleChange} className={validation.name ? 'error' : ''} type="text" name="name" value={formData.name} placeholder="Name" />
                                    <input onChange={handleChange} className={validation.email ? 'error' : ''} type="text" name="email" value={formData.email} placeholder="Email" />
                                    <textarea onChange={handleChange} className={validation.message ? 'error' : ''} name="message" value={formData.message} placeholder="Message" />


                                    <button type="submit">{loading ? 'Sending...' : <>Submit <span>&rarr;</span></>}</button>
                                    <p className={(validation.name || validation.email || validation.message) ? 'error' : ''}>Please fix the marked fields</p>
                                    <p className={error ? 'error' : ''}>Something went wrong. Please try again later.</p>
                                </form>
                                <div className="img-container">
                                    <img src={illustration} className={`mobile-only ${successScreen ? 'message-sent' : ''}`} alt="contact illustration" />
                                </div>
                            </animated.div>
                        ))}
                    </div>

                </animated.div>
            </div>
        </>
    )
}

export default Contact
