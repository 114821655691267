import axios from 'axios';

const service = axios.create({
    baseURL: 'https://maca.ba/api',
    withCredentials: false,
    headers: {
        Accept: 'text, application/json',
        'Content-Type': 'application/json',
    }
});

export const sendMail = requestData => {
    return service.post('/contact_us', requestData);
};