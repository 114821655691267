import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTransition } from 'react-spring';

import Contact from '../components/Contact';
import Header from '../components/Header';

import './index.scss';

function Index() {
  const [contactOpen, setContactOpen] = useState(false);
  const transition = useTransition(contactOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <div id="main">
      <Helmet>
        <title>Maca - Coming Soon</title>
      </Helmet>

      <Header onOpen={() => setContactOpen(true)} contactOpen={contactOpen} />

      {!contactOpen && <div className="Content__inner container">
        <div className="ComingSoon__container">
          {new Array(12).fill().map(() => <div className="ComingSoon__container__clock-item"></div>)}

          <h3>Our new site is</h3>
          <h1 className="ComingSoon__container__main">Coming <br /> Soon</h1>
          <h3>Stay tuned!</h3>

        </div>

        <button onClick={() => setContactOpen(true)} >Contact us</button>
        <div className="Content__footer">Marketing & Copywriting Agency</div>
      </div>}

      {
        transition((style, item) => item && (
          <Contact style={style} onClose={() => setContactOpen(false)} />
        ))
      }
    </div >
  );
}

export default Index;
