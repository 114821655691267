import React from 'react'

import Logo from '../images/maca-logo.png';
import MobileLogo from '../images/maca-mobile.png';

import './header.scss';

function Header(props) {
    return (
        <div className="Main__header container">
            <div className="Main__header-logo">
                <img src={Logo} className={`Main__header-logo__img ${props.contactOpen ? "faded" : ""}`} alt="Maca logo" />
                <img src={MobileLogo} className={`Main__header-logo__mobile-img ${props.contactOpen ? "faded" : ""}`} alt="Maca logo" />
            </div>

            <div className="Main__header-navigation">
                <a className={`${props.contactOpen ? "faded" : ""}`} onClick={props.onOpen}>Contact us</a>
            </div>
        </div>
    )
}

export default Header
